









































import Vue, {PropType} from "vue";
import {mapActions, mapGetters} from "vuex";
import router from "@/router";

export default Vue.extend({
  name: "change-subscription-confirm-modal" as string,
  data(){
    return {
      cancelDisabled: false
    }
  },

  props: {
    modalId: Number as PropType<number>,
    payloadData: Object as PropType<object>,
    resourceId: Number as PropType<number>,
    isSubscriptionChanged: Boolean as PropType<boolean>,
    emailMessages: Array as PropType<Array<string>>
  },

  computed: {
    ...mapGetters("venueSubscriptionModule", {
      loader: "GET_LOADER"
    })
  },

  methods: {
    ...mapActions("venueSubscriptionModule", {
      editVenueSubscription: "EDIT_VENUE_SUBSCRIPTION",
    }),
    ...mapActions("emailsModule", {
      paymentMethodChangedEmail: "SUBSCRIPTION_OR_PAYMENT_METHOD_CHANGED_EMAIL"
    }),
    async changeSubscriptionPlan(): Promise<void> {
      this.cancelDisabled = true;
      await this.editVenueSubscription({payload: this.payloadData, resourceId: this.resourceId})
      if (this.isSubscriptionChanged) {
        const payload: object = {
          changedEntity: "2",
          subscriptionChangeTexts: this.emailMessages
        }
        this.paymentMethodChangedEmail(payload);
      }
      setTimeout(function(){
        router.push({
          path: '/subscription-plan/details/' + router.currentRoute.params.id,
          params: { id: router.currentRoute.params.id }
        })
        this.$bvModal.hide("change-subscription-confirm-modal" + this.modalId);
        this.cancelDisabled = false
      },2000)

    }
  }
})
