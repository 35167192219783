























































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import SubscriptionPlanRedesign from "@/components/add-venue/SubscriptionPlanRedesign.vue";
import AdditionalServices from "@/components/add-venue/AdditionalServices.vue";
import PaymentPeriod from "@/components/add-venue/PaymentPeriod.vue";
import MusicStreamingMethod from "@/components/add-venue/MusicStreamingMethod.vue";
import SummaryPriceRedesign from "@/components/add-venue/SummaryPriceRedesign.vue";
import ChangeSubscriptionConfirmModal from "@/components/modals/ChangeSubscriptionConfirmModal.vue";
import {mapActions, mapGetters} from "vuex";
import { EventPlanData, SubscriptionProductsData, VenueSubscriptionData } from "@/types";
import {formatUnitPrice} from "@/utils/helpers";
import router from "@/router";
import i18n from "@/i18n/i18n";

export default Vue.extend({
  name: "subscription-plan-edit" as string,

  components: {
    Layout,
    "subscription-plan-redesign": SubscriptionPlanRedesign,
    "additional-services": AdditionalServices,
    "payment-period": PaymentPeriod,
    "music-streaming-method": MusicStreamingMethod,
    "summary-price-redesign": SummaryPriceRedesign,
    "change-subscription-confirm-modal": ChangeSubscriptionConfirmModal
  },

  data() {
    return {
      audioMessages: [],
      additionalServicesIds: [],
      webstreamer: 0,
      selectedSubscriptionProduct: null,
      checkboxGdpr: false,
      isSubmitted: false,
      setLoader: false,
      randomId: Math.floor(Math.random() * 100000),
      payloadData: {} as object,
      emailTexts: [] as Array<string>
    }
  },

  async mounted(): Promise<void> {
    await this.setVenue();
    await this.setSubscriptionProducts();
    await this.setVenueSubscription(router.currentRoute.params.id);
    await this.setTaxPeriodTranslation();
    await this.setBillingIntervalOnLoad();
    this.setAdditionalServices();

    if (!this.taxIsNotValid) await this.setTaxRates();
  },

  computed: {
    ...mapGetters("venueModule", {
      venue: "GET_SELECTED_VENUE",
      taxIsNotValid: "TAX_IS_NOT_VALID"
    }),
    ...mapGetters("venueSubscriptionModule", {
      subscriptionProducts: "GET_FILTERED_SUBSCRIPTION_PRODUCTS",
      allSubscriptionProducts: "GET_ALL_SUBSCRIPTION_PRODUCTS",
      recurringInterval: "GET_SELECTED_RECURRING_INTERVAL",
      selectedVenueSubscription: "GET_SELECTED_VENUE_SUBSCRIPTION",
      paymentInterval: "GET_RECURRING_INTERVAL",
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID",
      company: "GET_COMPANY"
    }),
    ...mapGetters("taxPricePeriodTranslationModule", {
      taxPricePeriodTranslation: "GET_TAX_PRICE_PERIOD_TRANSLATION"
    }),
    isPaymentMethod(): boolean {
      return !!this.selectedPaymentMethod;
    },
    activeSubscriptionProduct(): SubscriptionProductsData {
      if (
        !this.allSubscriptionProducts.length ||
        !this.selectedVenueSubscription.stripeProductWithPriceId
      ) 
        return;

      return this.allSubscriptionProducts.find(
        p => p.id === this.selectedVenueSubscription.stripeProductWithPriceId
      );
    },
    subscriptionProduct(): Array<SubscriptionProductsData> {
      return this.subscriptionProducts.filter(p => p.songorooServiceType === 1);
    },
    additionalSubscriptionProducts(): SubscriptionProductsData {
      return this.subscriptionProducts.filter(p => p.songorooServiceType === 2 && p.recurringInterval == this.paymentInterval && p.isActive == true);
    },
    finalPrice(): string {
      if (!this.allSubscriptionProducts || !this.selectedSubscriptionProduct) return "";

      // the price per month is always displayed, even if a variant of the year is selected
      const subscriptionProductMonthlyPrice = this.allSubscriptionProducts.find(s => s.productName === this.selectedSubscriptionProduct.productName && s.recurringInterval === this.paymentInterval).unitAmount;
      let audioMessagesTotal = 0;
      for (const additionalService of this.audioMessages) {
        audioMessagesTotal += additionalService.unitAmount
      }

      const taxMultiplier: number = this.taxIsNotValid ? 1.2 : 1;

      const price = (!!this.selectedSubscriptionProduct ? subscriptionProductMonthlyPrice : 0) + (audioMessagesTotal != 0 ? audioMessagesTotal : 0);
      return formatUnitPrice(Number(price) * taxMultiplier);
    },
    isSubscriptionProductChanged(): boolean {
      if (!this.activeSubscriptionProduct || !this.selectedSubscriptionProduct) return false;
      return this.activeSubscriptionProduct.productName !== this.selectedSubscriptionProduct.productName;
    },
    oldSubscriptionProduct(): string {
      if (!this.activeSubscriptionProduct) return "";
      return this.activeSubscriptionProduct.productName;
    },
    newSubscriptionProduct(): string {
      if (!this.selectedSubscriptionProduct) return "";
     return this.selectedSubscriptionProduct.productName;
    },
    isAdditionalServicesChanged(): boolean {
      if(!this.selectedVenueSubscription) return;
      let selectedVenueSubscriptionAdditionalServicesInArray = this.selectedVenueSubscription.additionalServices.split(' ')
      return JSON.stringify(selectedVenueSubscriptionAdditionalServicesInArray) !== JSON.stringify(this.additionalServicesIds)
    },
    isPaymentPeriodChanged(): boolean {
      return this.activeSubscriptionProduct.recurringInterval !== this.recurringInterval;
    },
    oldPaymentPeriod(): string {
      return this.activeSubscriptionProduct.recurringInterval;
    },
    newPaymentPeriod(): string {
      return this.recurringInterval;
    },
    isStreamingMethodChanged(): boolean {
      return this.selectedVenueSubscription.includedStreamingDevice !== !!this.webstreamer;
    },
    oldStreamingMethod(): boolean {
      return this.selectedVenueSubscription.includedStreamingDevice;
    },
    newStreamingMethod(): boolean {
      return !!this.webstreamer;
    },
    isAnyChangeInSubscriptionPlan(): boolean {
      return this.isSubscriptionProductChanged || this.isAdditionalServicesChanged || this.isPaymentPeriodChanged || this.isStreamingMethodChanged;
    },
    locale(){
      return i18n.locale
    },
    priceAndPeriodInformation(){
      if(!this.taxPricePeriodTranslation || !this.paymentInterval) return "";
      return this.locale == "en" ? this.taxPricePeriodTranslation.priceAndPeriodMonthEn : (this.taxPricePeriodTranslation.isPriceAndPeriodMonthTranslationCompleted ? this.taxPricePeriodTranslation.priceAndPeriodMonthSk : this.taxPricePeriodTranslation.priceAndPeriodMonthEn);
    },
    taxInfo(){
      if(!this.taxPricePeriodTranslation) return "";
      return this.locale == "en" ? this.taxPricePeriodTranslation.taxInformationEn : (this.taxPricePeriodTranslation.isTaxInformationCompleted ? this.taxPricePeriodTranslation.taxInformationSk : this.taxPricePeriodTranslation.taxInformationEn);
    },
    additionalOrder(){
      return router.currentRoute.query.audio_messages;
    },
  },

  methods: {
    ...mapActions("venueModule", {
      setVenue: "FETCH_VENUE",
      updateVenue: "UPDATE_VENUE"
    }),
    ...mapActions("venueSubscriptionModule", {
      setSubscriptionProducts: "FETCH_SUBSCRIPTION_PRODUCTS",
      setVenueSubscription: "FETCH_VENUE_SUBSCRIPTION",
      setRecurringInterval: "SET_RECURRING_INTERVAL",
      setTaxRates: "FETCH_TAX_RATE"
    }),
    ...mapActions("emailsModule", {
      paymentMethodChangedEmail: "SUBSCRIPTION_OR_PAYMENT_METHOD_CHANGED_EMAIL"
    }),
    ...mapActions("taxPricePeriodTranslationModule", {
      setTaxPeriodTranslation: "FETCH_TAX_PRICE_PERIOD_TRANSLATION"
    }),
    setAudioMessages(value: EventPlanData): void {
      this.audioMessages.push(value);
      this.additionalServicesIds.push(value.id)
    },
    removeAdditionalService(value: EventPlanData): void {
      const removeIndex = this.audioMessages.map(item => item.id).indexOf(value.id);
      ~removeIndex && this.audioMessages.splice(removeIndex, 1);
      ~removeIndex && this.additionalServicesIds.splice(removeIndex, 1);
    },
    setAdditionalServices(){
      if(!this.selectedVenueSubscription) return;
      if(this.selectedVenueSubscription.additionalServices.length == 0 ) return;
      for (const additionalService of this.selectedVenueSubscription.additionalServices.split(" ")) {
        this.additionalServicesIds.push(additionalService)
        const product = this.allSubscriptionProducts.find(product => product.id == additionalService)
        this.audioMessages.push({id: additionalService, unitAmount: product.unitAmount})
      };
    },
    setWebstreamer(value: boolean): void {
      this.webstreamer = value;
    },
    setSubscriptionProduct(value: SubscriptionProductsData): void {
      this.selectedSubscriptionProduct = value;
    },
    streamingMethodVariantsTexts(value: boolean): string {
      // return value ? this.$t("Streaming device") : this.$t("Web Based Streaming");
      return value ? "Streaming device" : "Web Based Streaming";
    },
    async openChangeConfirmModal(): Promise<void> {
      const isStreamerDeviceAddedToSubscription = this.isStreamingMethodChanged && this.webstreamer === 1;
      this.payloadData = {
        stripeProductWithPriceId: this.selectedSubscriptionProduct ? this.selectedSubscriptionProduct.id : this.selectedVenueSubscription.stripeProductWithPriceId,
        additionalServices: this.additionalServicesIds.join(' '),
        includedStreamingDevice: this.webstreamer,
        isStreamerDeviceAddedToSubscription: isStreamerDeviceAddedToSubscription,
        amount: this.selectedSubscriptionProduct ? this.selectedSubscriptionProduct.unitAmount : this.selectedVenueSubscription.packageFeeVatIncluded
      };

      if (this.isAnyChangeInSubscriptionPlan) {
        this.createTextsForEmail();
      }

      this.$bvModal.show("change-subscription-confirm-modal" + this.randomId);
    },
    createTextsForEmail(): void {
      this.emailTexts = [];

      if (this.isSubscriptionProductChanged) {
        this.emailTexts.push(this.$t(`Your subscription product was changed from ${this.oldSubscriptionProduct} to ${this.newSubscriptionProduct}.`));
      }

      if (this.isAdditionalServicesChanged) {
        let selectedVenueSubscriptionAdditionalServicesInArray = this.selectedVenueSubscription.additionalServices.split(' ');
        let activatedServicesText = ''
        let deactivatedServicesText = ''
        // activated services
        let activatedServicesIds = this.additionalServicesIds.filter(x => !selectedVenueSubscriptionAdditionalServicesInArray.includes(x))
        activatedServicesIds = activatedServicesIds.filter(n => n)
        if(activatedServicesIds.length !== 0){
          for(let index = 0; index < activatedServicesIds.length; index++){
            let additionalService = this.allSubscriptionProducts.find(product => {

              return product.id == activatedServicesIds[index];
            })
            let additionalServiceTranslatedName = this.locale === 'sk' ? additionalService.productNameSk : additionalService.productName;
            if (activatedServicesIds.length-1 === index){
              activatedServicesText += `${additionalServiceTranslatedName} (${additionalService.recurringInterval === 'month' ? this.$t('monthly subscription') : this.$t("yearly subscription")})`
            } else {
              activatedServicesText += `${additionalServiceTranslatedName} (${additionalService.recurringInterval === 'month' ? this.$t('monthly subscription') : this.$t("yearly subscription")}), `
            }
          }
          if(activatedServicesText.length > 0){
            activatedServicesText += ' ' + this.$t("have been activated.");
          }
          this.emailTexts.push(activatedServicesText);
        }

        // deactivated services
        let deactivatedServicesIds = selectedVenueSubscriptionAdditionalServicesInArray.filter(x => !this.additionalServicesIds.includes(x))
        deactivatedServicesIds = deactivatedServicesIds.filter(n => n)
        if(deactivatedServicesIds.length !== 0){
          for(let index = 0; index < deactivatedServicesIds.length; index++){
            let additionalService = this.allSubscriptionProducts.find(product => {
              return product.id == deactivatedServicesIds[index];
            })
            let additionalServiceTranslatedName = this.locale === 'sk' ? additionalService.productNameSk : additionalService.productName;
            if (deactivatedServicesIds.length-1 === index){
              deactivatedServicesText += `${additionalServiceTranslatedName} (${additionalService.recurringInterval === 'month' ? this.$t('monthly subscription') : this.$t("yearly subscription")})`
            } else {
              deactivatedServicesText += `${additionalServiceTranslatedName} (${additionalService.recurringInterval === 'month' ? this.$t('monthly subscription') : this.$t("yearly subscription")}), `
            }
          }
          if(deactivatedServicesText.length > 0){
            deactivatedServicesText += ' ' + this.$t("have been deactivated.");
          }
          this.emailTexts.push(deactivatedServicesText);
        }
      }

      if (this.isPaymentPeriodChanged) {
        this.emailTexts.push(this.$t(`Your payment period was changed from ${this.oldPaymentPeriod} to ${this.newPaymentPeriod}.`));
      }

      if (this.isStreamingMethodChanged) {
        this.emailTexts.push(this.$t(`Your music streaming method was changed from ${this.streamingMethodVariantsTexts(this.oldStreamingMethod)} to ${this.streamingMethodVariantsTexts(this.newStreamingMethod)}.`));
      }
    },
    onBillingPeriodicityChange(){
      this.audioMessages = [];
      this.additionalServicesIds = [];
    },
    async setBillingIntervalOnLoad() {
      if (!this.company) {
        this.setRecurringInterval("month");
      } else {
        const recurringInterval = this.company.corporate
          ? this.company.billing_interval === 1
            ? "month"
            : "year"
          : "month";
        this.setRecurringInterval(recurringInterval);
      }
    },
  },

  watch: {
    activeSubscriptionProduct: {
      handler(value: SubscriptionProductsData): void {
        if (value) {
          this.setRecurringInterval(value.recurringInterval);
        }
      }
    },
    selectedVenueSubscription: {
      handler(value: VenueSubscriptionData): void {
        if (value) {
          this.selectedPaymentType = value.paymentType
          this.selectedPaymentMethod = value.paymentMethodId;
        }
      }
    },
    companyId: {
      handler(newValue: number, oldValue: number): void {
        if (newValue != oldValue) {
          this.$router.push("/subscription-plan");
        }
      }
    }
  }
})
